import React from 'react';
// import PropTypes from 'prop-types';
import {Match} from '@reach/router';
import StepContainer from 'modules/steps/containers/StepContainer';
import NotFoundPage from 'theme/pages/NotFound';

const DegreeCourseDetailsPage = () => {
  return (
    <Match path="/degrees/:trackSlug/:courseSlug/:stageSlug/:stepSlug">
      {({match}) =>
        match ? <StepContainer {...match} isDegree /> : <NotFoundPage />
      }
    </Match>
  );
};

DegreeCourseDetailsPage.propTypes = {};

export default DegreeCourseDetailsPage;
